.message-bubble {
    align-items: flex-end;
    flex-shrink: 0;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    position: relative;
    width: fit-content;
    max-width: 385px;
    margin-bottom: 25px;
  }
  
  .message-bubble .messagebubble-avatar {
    background-image: url(../assets/img/ksk.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 30px;
    position: relative;
    width: 30px;
  }
  
  .message-bubble .message-send-time {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 12px;
    justify-content: flex-end;
    position: relative;
  }
  
  .message-bubble .bubble-tail {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .message-bubble .tail {
    height: 20.32px;
    margin-bottom: -0.32px;
    margin-left: -5px;
    position: relative;
    width: 16.42px;
    left:11.5px;
    fill:#E9E9EB;
  }
  
  .message-bubble .bubble {
    align-items: flex-start;
    align-self: stretch;
    background-color: #e9e9eb;
    border-radius: 18px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    padding: 6px 12px;
    position: relative;
  }
  
  .message-bubble .message {
    color: rgba(0, 0, 0, 1);
    flex: 1;
    font-family: "Maven Pro-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.41px;
    line-height: 22px;
    position: relative;
  }
  
.message-bubble.sender {
  margin-left: auto;
}
.message-bubble.sender .tail {
  left:-7px;
  transform: scaleX(-1);
  fill: #448AF7;
}
.message-bubble.sender .bubble{
  background-color: #448AF7;
}
.message-bubble.sender .message{
  color:#fff;
}