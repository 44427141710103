*{
  margin:0;
  padding:0;
}
html{
  overflow:hidden;
}

.App{
  background-color: #222222;
  background-image: url(assets/svg/background.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-attachment: fixed;
  /* background-attachment:local,scroll;
  transform:translatez(0);
  -webkit-transform:translatez(0); */
}
body,#root{overflow: hidden;}

html, body, #root, .App {
  height: 100%;
}

.content{
  margin: 10px;
  margin-bottom: 5%;
  max-width: 650px;
}

.actionButtons{
  display: inline-flex;
  flex-flow: row wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12vh;
  height: auto;
}
.background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 1978px; /* Set the height of your SVG */
  z-index: 0; /* Place the SVG behind other content */
}

.scrollContainer{
  overscroll-behavior:contain;
  overscroll-behavior-y: contain;
  overflow: auto;
  height: 85vh;
}

@media only screen and (min-width: 800px) {
  .scrollContainer {
    margin-left: 30%;
  }
  .actionButtons{
    justify-content: normal;
  }
}

@media only screen and (max-width: 600px) {
  #clubname{
    font-size: 41px;
  }
  .right{
    font-size: 11px;
  }
}