.message-bubble-img {
    align-items: flex-end;
    flex-shrink: 0;
    display: flex;
    height: fit-content;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    margin-bottom: 3vh;
    margin-top: 3vh;
    max-width: 900px;
  }
  
  .message-bubble-img .messagebubble-avatar {
    background-image: url(../assets/img/ksk.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 30px;
    position: relative;
    width: 30px;
  }
  
  .message-bubble-img .message-send-time {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 12px;
    justify-content: flex-end;
    margin-left: -9px;
    margin-top: -20.75px;
    position: relative;
  }
  
  .message-bubble-img .bubble-tail {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
  
  .message-bubble-img .tail {
    height: 20px;
    position: relative;
    width: 11px;
  }
  
  .message-bubble-img .bubble {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 18px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    padding: 6px 12px;
    position: relative;
  }
  
  .message-bubble-img .rectangle {
    margin-right: -7px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }
  