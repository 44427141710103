.box {
    height: 39px;
    width: 181px;
    cursor: pointer;
}

.box:active{
    transform: translateY(4px);
}

.box .group {
    height: 39px;
    width: 183px;
}

.box .overlap-group {
    border-radius: 41.5px;
    height: 39px;
    position: relative;
    width: 181px;
}

.box .rectangle {
    background-color: #448af7;
    border-radius: 41.5px;
    height: 39px;
    left: 0;
    position: absolute;
    top: 0;
    width: 181px;
}

.box .text-wrapper {
    color: #ffffff;
    font-family: "Maven Pro-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 24px;
    left: 15px;
    letter-spacing: -0.41px;
    line-height: 22px;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
    width: 151px;
}